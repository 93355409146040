.status-bar {
    background-color: #0C0C0C;
    height: 24px;
}

.header-logo {
    display: flex;
    justify-content: center;
    position: relative;
}

.main-logo {
    display: flex;
    max-width: 190px;
    width: 50%;
    height: auto;
}
.main-logo img{
    width: 100%;
    height: auto;
    display: flex;
}
.img-icon-back{
    position: absolute;
    left: 15px;
    top: 10%;
    z-index: 98;
}
.header p {
    text-align: center;
    font-size: 24px;
    color: #FFFFFF;
    margin: 0;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    line-height: 29px;
    margin-top: 10px
}