
.search-user{
    margin-top: 50px;
}
.search-user span{
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF; 
}

.input-search{
    margin-top:15px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #333;
    border-radius: 25px;
    overflow: hidden;
    padding-left: 12px;
    border: 0.78px solid #A19999;
    position: relative;
}

.input-search input{
    margin-left: 34px;
    border: none;
    outline: none;
    background-color: #333;
    color: #FFFFFF;
    font-size: 16px;
    height: 100%;
    width: 100%;
}
.icon-search{
    position: absolute;
    left: 16px;
    width: 24px;
    height: auto;
}
.btn-search-account{
    position: absolute;
    cursor: pointer;
    border-radius: 128px;
    border: 2px solid #ccc6c6;
    background: linear-gradient(90deg, #f7b922 0%, #f49a25 100%);
    color: #FFFFFF;
    padding: 8px;
    font-size: 18px;
    font-weight: bold;
    max-width: 120px;
    width: 100%;
    right: 2px;
}

.description{
    margin-top:30px;
    border-radius: 25px;
    background: linear-gradient(90deg, #FDFFFF 0%, #E0F7FF 100%);
    border: 0.78px solid #0798C6;
    padding: 10px 15px 10px 15px;
}

.description p{
    font-weight: 500;
    font-size: 16px;    
    line-height: 150%;
    font-style: italic;
    text-align: justify;
}

@media only screen and (max-width: 350px) {

}