*{
    font-family: 'Roboto', sans-serif;
}

.profile-content {
    border: 1.8px solid #ccc6c6;
    border-radius: 10px;
    margin-top:15px;
    margin-bottom:15px;
}

.info {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.info span {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 350;
}
