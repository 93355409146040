@import url("https: //fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body{
    margin: 0;
}
.container{
    background-image: url("../../imgs/bg-cskh.webp");
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    background-repeat: repeat;
    background-size: 100% 100%;
}

.app{
    max-width: 430px;
    width: 100%;
    /* height: 873px; */
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: #121212;
    background-image: url("../../imgs/background.png");
    background-blend-mode: color-burn;
}

.content {
    padding-left:15px;
    padding-right:15px;
    font-family: "Roboto", sans-serif;
}
.error {
    color: #f70f0ff2;
    font-size: 14px;
    font-style: italic;
}
p.error {
    margin: 0px 10px;
}